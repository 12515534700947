<template>
  <moe-page title="商品管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="goodsSearch">
      <el-form-item label="商品编号">
        <el-input :value="goodsParams.id" @input="(value) => goodsParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入商品编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input v-model.trim="goodsParams.name" placeholder="输入商品名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="关联品牌">
        <el-select class="w-300" v-model="goodsParams.brandId" filterable placeholder="选择关联品牌" clearable>
          <el-option
            v-for="(item, index) in brandList"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品类目">
        <moe-cascader class="w-300" :checkStrictly="true" placeholder="选择商品类目" v-model="categorySelectList" :options="categoryList"></moe-cascader>
      </el-form-item>
      <el-form-item label="上架状态">
        <moe-select v-model="goodsParams.status" type="goodsStatus" placeholder="选择状态" />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([goodsParams.startTime, goodsParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 商品信息 -->
    <moe-table ref="goodsTable" url="/shop/goods/queryShopGoodsList" :params="goodsParams" :number-show="false" row-key="id">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="success" icon="el-icon-top" @click="handleShelf('批量上架', 1)">批量上架</el-button>
        <el-button type="warning" icon="el-icon-bottom" @click="handleShelf('批量下架', 2)">批量下架</el-button>
        <el-button type="danger" icon="el-icon-delete" @click="batchDelete">批量删除</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/goods/add')">添加商品</el-button>
      </template>
      <el-table-column type="selection" reserve-selection label="id" width="60" fixed="left" />
      <el-table-column prop="id" label="商品编号" min-width="80" />
      <el-table-column label="商品图片" width="130">
        <template slot-scope="{ row }">
          <moe-image :src="row.coverUrl" width="80px" height="80px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" :show-overflow-tooltip="true" label="商品名称" min-width="130" />
      <el-table-column label="销售价" min-width="150">
        <template slot-scope="{ row }">
          {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="buyNum" label="真实销量" min-width="80" />
      <el-table-column prop="fakeBuyNum" label="虚拟销量" min-width="120">
        <template slot-scope="{ row }">
          <div v-if="row.edit">
            <el-input :value="row.fakeBuyNum" @input="(value) => row.fakeBuyNum = value.replace(/^\.+|[^\d]/g, '')" placeholder="请输入虚拟销量" maxlength="10" clearable></el-input>
          </div>
          <div class="df aic jcc" v-else-if="!row.edit" @click="row.edit = !row.edit">
            <div class="color-blue bb-blue lh-16 cp">{{ row.fakeBuyNum }}</div>
          </div>

          <template v-if="row.edit">
            <div class="df aic jcc mt-5">
              <el-button type="success" size="mini" @click="shopGoodsFakeBuyNum(row)">确定</el-button>
              <el-button type="info" size="mini" @click="handleCancel()">取消</el-button>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="库存" min-width="80">
        <div class="df aic jcc" slot-scope="{ row }">
          <div class="color-danger bb-danger lh-16 cp" @click="queryShopGoodsById(row.id)">{{ row.stock }}</div>
        </div>
      </el-table-column>
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.createTime) }}</div>
          <div :class='`statusBtn${row.status}`'>{{ row.status === 1 ? '出售中' : '仓库中' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="520" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button :type="item.type(row)" :icon="item.icon(row)" size="mini" :plain="item.plain(row)" @click="item.clickHandle(row)" v-for="(item, index) in buttonList" :key="index">{{ item.title(row) }}</el-button>
        </div>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="修改库存" width="50%" @close="handleDialogClose()" :autoHeight="true">
      <div class="dialog_content">
        <moe-form
          ref="goodsItemForm"
          class="default-form"
          :showBack="false"
          :showTool="false"
          :model="form"
          :rules="rules">
          <moe-table
            url=""
            :maxHeight="480"
            :data="form.goodsItem"
            :number-show="false"
            :mode="false"
            :params="{ pageNum: 1 }">
            <el-table-column label="商品编号" prop="goodsId" width="100" />

            <el-table-column label="SKU" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="销售价（￥）" prop="salePrice" min-width="100" />

            <el-table-column label="库存">
              <template slot-scope="{ row, $index }">
                <el-form-item :prop="`goodsItem.${$index}.stock`" :rules="rules.stock">
                  <el-input :value="row.stock" @input="(value) => changeStock(value, $index)" placeholder="请输入库存" maxlength="50" clearable />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column label="图片" width="250">
              <template slot-scope="{ row }">
                <moe-image :src="row.coverUrl" width="100px" height="100px"></moe-image>
              </template>
            </el-table-column>
          </moe-table>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" :loading="goodsItemLoad" @click="handleConfirm()">{{ goodsItemLoad ? '保存中' : '确定' }}</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsList',
  data() {
    const verify = this.$moe_verify.verifyForm;
    //日志筛选信息
    let goodsParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      id: '',
      name: '',
      brandId: '',
      oneClass: '',
      twoClass: '',
      threeClass: '',
      status: '',
      startTime: '',
      endTime: '',
    };

    return {
      goodsParams, //日志筛选信息
      dateTime: [],
      showDialog: false,
      form: {
        goodsItem: [],
      },
      rules: {
        stock: verify.isEmpty('请输入库存'),
      },
      goodsItemLoad: false,
      brandList: [],
      categorySelectList: [],
      categoryList: [],
    };
  },
  watch: {
    categorySelectList(newValue) {
      if (newValue.length) {
        this.goodsParams.oneClass = newValue[0];
        this.goodsParams.twoClass = newValue[1] && newValue[1];
        this.goodsParams.threeClass = newValue[2] && newValue[2];
      } else {
        this.goodsParams.oneClass = this.goodsParams.twoClass = this.goodsParams.threeClass = '';
      }
    }
  },
  computed: {
    buttonList() {
      return [
        // {
        //   title: () => '修改库存',
        //   type: () => 'warning',
        //   plain: () => false,
        //   icon: () => 'el-icon-edit',
        //   clickHandle: ({ id }) => {
        //     this.queryShopGoodsById(id);
        //   }
        // },
        {
          title: ({ status }) => { return status === 1 ? '立即下架' : '立即上架' },
          type: ({ status }) => { return status === 1 ? 'info' : 'primary' },
          plain: ({ status }) => { return status === 1 ? true : false },
          icon: ({ status }) => { return status === 1 ? 'el-icon-download' : 'el-icon-upload2' },
          clickHandle: (row) => {
            this.statusHandle(row)
          }
        },
        {
          title: () => '编辑',
          type: () => 'warning',
          plain: () => false,
          icon: () => 'el-icon-edit',
          clickHandle: ({ id }) => {
            this.$router.push(`/goods/amend?id=${id}`)
          }
        },
        {
          title: () => '删除',
          type: () => 'danger',
          plain: () => false,
          icon: () => 'el-icon-delete',
          clickHandle: ({ id, status }) => {
            if (status === 1) {
              this.$alert('上架中的商品不能删除，请先下架商品再删除', '提示', {
                confirmButtonText: '确定',
                callback: action => {}
              });
            } else {
              this.goodsDelete(id)
            }

          }
        },
        {
          title: () => '关联设备',
          type: () => 'success',
          plain: () => false,
          icon: () => 'el-icon-view',
          clickHandle: ({ id }) => {
            this.$moe_coordinator.navigateTo('/goods/device', { id })
          }
        },
        {
          title: () => '商品推荐',
          type: () => 'info',
          plain: () => false,
          icon: () => 'el-icon-thumb',
          clickHandle: ({ id }) => {
            this.$moe_coordinator.navigateTo('/goods/recommend', { id })
          }
        },
        // {
        //   title: () => '详情',
        //   type: () => 'success',
        //   plain: () => false,
        //   icon: () => 'el-icon-view',
        //   clickHandle: ({ id }) => {
        //     this.$router.push(`/goods/detail?id=${id}`)
        //   }
        // },
        // {
        //   title: () => '日志',
        //   type: () => '',
        //   plain: () => false,
        //   icon: () => 'el-icon-upload',
        //   clickHandle: ({ id }) => {
        //     this.$router.push(`/user/detail?id=${id}`)
        //   }
        // }
      ]
    }
  },
  methods: {
    handleConfirm() {
      this.$refs['goodsItemForm'].validate(() => {
        this.goodsItemLoad = true;
        let list = this.form.goodsItem.map(({ id, stock }) => {
          return {
            id,
            stock,
          }
        })
        this.$moe_api.GOODS_API.updateShopGoodsItemStock({ list }).then((data) => {
          if (data.code === 200) {
            this.handleDialogClose();
            this.$moe_msg.success('保存成功');
            this.$refs['goodsTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.goodsItemLoad = false;
        });
      })
    },
    handleDialogClose() {
      this.showDialog = false;
    },
    changeStock(value, index) {
      let findItem = this.form.goodsItem[index];
      findItem.stock = value
      try {
        findItem.stock = value.replace(/[^0-9.]/g, "").replace(/^0+(\d)|(\.)/, '$1')
      } catch {
        console.log('error')
      }
    },
    /** 分页查询品牌列表 */
    getBrandList() {
      this.$moe_api.BRAND_API.getBrandList({ pageNum: 1, pageSize: 9999, status: true }).then((data) => {
        if (data.code === 200) {
          this.brandList = data.result.list.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            }
          })
        }
      })
    },
    /** 商品详情 */
    queryShopGoodsById(id) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;
          this.form.goodsItem = r.goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
            return {
              ...item,
              name: r.name,
              goodsId: r.id,
            }
          })
          this.showDialog = true;
        } else {
          this.$moe_msg.eror(data.message);
        }
      })
    },
    /** 修改商品虚拟销量 */
    shopGoodsFakeBuyNum({ fakeBuyNum, id }) {
      if (!fakeBuyNum) {
        return this.$moe_msg.warning('请输入虚拟销量')
      }
      this.$moe_api.GOODS_API.shopGoodsFakeBuyNum({ fakeBuyNum, id }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('修改成功');
          this.$refs['goodsTable'].searchData();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 商品上下架 */
    statusHandle(item) {
      this.$confirm(`您确定${item.status === 1 ? '商品下架' : '商品上架'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$moe_api.GOODS_API.shopGoodsShelf({ id: item.id, status: item.status === 1 ? 2 : 1 }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success(`${item.status === 1 ? '商品下架' : '商品上架'}成功`);
            this.$refs.goodsTable.loadingData();
          } else {
            this.$moe_msg.error(data.message)
          }
        })
      })
    },
    /**
     * 商品搜索
     **/
    goodsSearch(isSearch) {
      if (!isSearch) {
        this.goodsParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          id: '',
          name: '',
          brandId: '',
          oneClass: '',
          twoClass: '',
          threeClass: '',
          status: '',
          startTime: '',
          endTime: '',
        };
        this.dateTime = [];
        this.categorySelectList = [];
      }

      //刷新商品表格
      this.$refs['goodsTable'].clearLoadData();
    },
    handleCancel() {
      this.$refs['goodsTable'].searchData();
    },
    /**
      * 商品删除
      */
    goodsDelete(ids) {
      this.$moe_layer.confirm('是否要删除该商品 ?', () => {
        this.$moe_api.GOODS_API.goodsDelete({ ids }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs.goodsTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /** 批量商品上下架 */
    handleShelf(text, status) {
      //获取已选数据ID
      let ids = this.$refs.goodsTable.getSelectId();
      if (!ids.length) {
        this.$moe_msg.warning('请选择数据 !');
        return;
      }
      this.$moe_layer.confirm(`您确定要"${text}"这些商品吗？`, () => {
        this.$moe_api.GOODS_API.shopGoodsShelfBatch({ ids, status }).then((data) => {
          if (data.code === 200) {
            if (data.result.list.length) {
              this.$refs.goodsTable.clearLoadData();
              let text = data.result.list.map(({ id, reason }) => {
                return `<div class="wsn">商品编号: "${id}" ${reason}</div>`
              }).join('')
              this.$moe_layer.alert(text);
            } else {
              this.$moe_msg.success('操作成功');
              this.$refs.goodsTable.clearLoadData();
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /**
     * 批量删除
     **/
    batchDelete() {
      //获取已选数据ID
      let ids = this.$refs.goodsTable.getSelectId();
      if (!ids.length) {
        this.$moe_msg.warning('请选择数据 !');
        return;
      }

      let selectionData = this.$refs.goodsTable.selectionData.filter(({ status }) => status === 1)

      if (selectionData.length) {
        return this.$moe_layer.alert('上架中的商品不能删除，请先下架商品再删除')
      }

      this.$moe_layer.confirm('是否要删除选中的商品信息 ?', () => {
        // 调用删除商品API
        this.$moe_api.GOODS_API.goodsDelete({ ids: ids.join(',') }).then(
          (data) => {
            if (data.code == 200) {
              this.$moe_msg.success('删除成功');

              //刷新商品表格
              this.$refs.goodsTable.clearLoadData();
            } else {
              this.$moe_msg.error(data.message);
            }
          }
        );
      });
    },
    /** 获取分类列表 */
    categoryListTree() {
      this.$moe_api.GOODS_API.categoryListTree().then((data) => {
        if (data.code === 200) {
          this.categoryList = data.result.map((item) => {
            return {
              status: item.status,
              label: item.name,
              value: item.id,
              children: item.childrens && item.childrens.map((itemChildItem) => {
                return {
                  status: itemChildItem.status,
                  label: itemChildItem.name,
                  value: itemChildItem.id,
                  children: itemChildItem.childrens && itemChildItem.childrens.map((itemChildItemChildItem) => {
                    return {
                      status: itemChildItemChildItem.status,
                      label: itemChildItemChildItem.name,
                      value: itemChildItemChildItem.id
                    }
                  })
                }
              })
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.getBrandList();
    this.categoryListTree();
  }
};
</script>
<style lang="scss">
</style>